import React from 'react';
import { Link } from 'react-router-dom';

const Hero = () => {
    return (
        <section className="hero">
            <h2>Ronan's Books</h2>
            <h3>You can order signed copies of my books here.<br/>
            Also available from <a href="http://somervillepress.com/">Somerville Press</a> and <a href="https://www.moosehousepress.com/">Moose House Press</a>.
            </h3>
            <Link className="btn" to="/books">View All Books</Link>
        </section>
    )
}

export default Hero
