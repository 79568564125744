import React from 'react';

var style = {
    backgroundColor: "#F8F8F8",
    borderTop: "1px solid #E7E7E7",
    textAlign: "center",
    padding: "20px",
    position: "fixed",
    left: "0",
    bottom: "0",
    height: "50px",
    width: "100%",
}

var phantom = {
  display: 'block',
  padding: '20px',
  height: '60px',
  width: '100%',
}

function Footer() {
    return (
        <div>
            <div style={phantom} />
            <footer style={style}>
                Copyright &copy; { new Date().getFullYear() }&nbsp;
                <a style={{color: "darkgray"}} href="https://ronanodriscoll.com/">Ronan O'Driscoll</a>. 
                Site inspired by <a style={{color: "darkgray"}} href="https://www.enlear.com/">Enlear Academy</a>.
            </footer>
        </div>
    )
}

export default Footer