import React from 'react';
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
// Amplify
import Amplify from "aws-amplify";

// Pages
import Home from "./pages/Home"
import Error from "./pages/Error";
import Books from "./pages/Books";
import Cart from "./pages/Cart";
import Checkout from "./pages/Checkout";
import BookDetails from "./pages/BookDetails";
import Admin from './pages/Admin';

// Components
import Header from "./components/Header"
import Flash from './components/Flash';
import Footer from './components/Footer';
import Bus from './utils/Bus'

// Amplify Configurations
import awsExports from "./aws-exports";

Amplify.configure(awsExports);

// Set up Flash
window.flash = (message, type="success") => Bus.emit('flash', ({message, type}));

const App = () => {
  return (
    <Router>
      <Flash />
      <Header />
      <Switch>
        <Route exact path="/">
          <Home />
        </Route>
        <Route path="/cart">
          <Cart />
        </Route>
        <Route path="/checkout">
          <Checkout />
        </Route>
        <Route exact path="/books">
          <Books />
        </Route>
        <Route
          path="/books/:id"
          children={<BookDetails></BookDetails>}>
        </Route>
        <Route path="/admin">
          <Admin />
        </Route>
        <Route path="*">
          <Error />
        </Route>
      </Switch>
      <Footer/>
    </Router>
  );
}

export default App;
