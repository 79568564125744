import React, { useContext } from "react";
import { AmplifyAuthenticator } from '@aws-amplify/ui-react';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import { CartContext } from "../context/cart";
import CheckoutForm from "../components/CheckoutForm";
import { Link } from 'react-router-dom';
import stripeLogo from '../assets/stripe.png';

const Checkout = () => {
    const stripePromise = loadStripe('pk_live_JiU08hI9dnwqDYbuVSTRmbsm00PrpoAM31');
    const { cart } = useContext(CartContext);

    if (cart.length === 0) {
        return (
            <section className="checkout-wrapper">
                <section>
                    <h2>Cart Empty</h2>
                    <p>
                        Check out the <Link style={{color: 'darkblue'}} to="/books">Books</Link> section for items to purchase.
                    </p>
                </section>
            </section>
        )
    } else {
        return (
            <section className="checkout-wrapper">
                <AmplifyAuthenticator>
                    <Elements stripe={stripePromise}>
                        <section>
                            <h2>Time to Checkout?</h2>
                            <CheckoutForm />
                            <br/>
                            <a href="https://stripe.com/"><img alt="Stripe Logo" src={stripeLogo}></img></a>
                        </section>
                    </Elements>
                </AmplifyAuthenticator>
            </section>
        )
    }
}

export default Checkout
