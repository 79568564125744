import React, { useState, useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";
import { BookContext } from "../context/books";
import { CartContext } from "../context/cart";
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";

const CARD_ELEMENT_OPTIONS = {
  style: {
    base: {
      color: "#32325d",
      fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
      fontSmoothing: "antialiased",
      fontSize: "16px",
      "::placeholder": {
        color: "#aab7c4"
      }
    },
    invalid: {
      color: "#fa755a",
      iconColor: "#fa755a"
    }
  }
};

const CheckoutForm = () => {
  const { cart, total, clearCart } = useContext(CartContext);
  const { checkout } = useContext(BookContext);
  const [orderDetails, setOrderDetails] = 
      useState({ cart, total, address: null, shipping: null, token: null, inscription: null });
  const [error, setError] = useState(null);
  const stripe = useStripe();
  const elements = useElements();
  const history = useHistory();

  useEffect(() => {
    if (orderDetails.token) {
      checkout(orderDetails);
      clearCart();
      history.push("/");
    }
  }, [orderDetails]);

  // Handle real-time validation errors from the card Element.
  const handleChange = (event) => {
    if (event.error) {
      setError(event.error.message);
    } else {
      setError(null);
    }
  };

  // Handle form submission.
  const handleSubmit = async (event) => {
    event.preventDefault();
    // validation
    if (orderDetails.address === null || orderDetails.address.length <= 10) {
      setError('Please enter a valid address.');
      return;
    }
    if (orderDetails.shipping === null || orderDetails.shipping === "0") {
      setError('Please select a shipping option.');
      return;  
    } else { // convert shipping to Float
      let shipping = orderDetails.shipping;
      if (shipping === "1") {
        shipping = "0"; // Free for HRM.
      }
      orderDetails.shipping = parseFloat(shipping);
    }
    const card = elements.getElement(CardElement);
    const result = await stripe.createToken(card);
    if (result.error) {
      // Inform the user if there was an error.
      setError(result.error.message);
    } else {
      setError(null);
      // Send the token to your server.
      const token = result.token;
      setOrderDetails({ ...orderDetails, token: token.id });
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className="checkout-form">
        <label className="form-label" htmlFor="checkout-address">Enter address for me to ship to you</label>
        <textarea
          rows="2"
          className="form-textarea"
          placeholder="Shipping address"
          id="checkout-address"
          onChange={(e) => setOrderDetails({ ...orderDetails, address: e.target.value })}
        />
        <label className="form-label" htmlFor="checkout-inscription">A personal message with the signature</label>
        <input
          className="form-input"
          placeholder="Optional Inscription"
          id="checkout-inscription"
          onChange={(e) => setOrderDetails({ ...orderDetails, inscription: e.target.value })}
        />
        <label className="form-label" htmlFor="shipping-selector">Select shipping cost from Nova Scotia</label>
        <select 
          defaultValue="0"
          className="form-input"
          id="shipping-selector"
          onChange={(e) => setOrderDetails({ ...orderDetails, shipping: e.target.value })}
        >
          <option value="0"></option>
          <option value="1">Halifax dropoff - FREE</option>
          <option value="6">Canada Letter - $6</option>
          <option value="12">Canada/USA Tracking - $12</option>
          <option value="15">International - $15</option>
        </select>
        <div className="stripe-section">
          <label className="form-label" htmlFor="stripe-element"> Credit or debit card </label>
          <CardElement id="stripe-element" options={CARD_ELEMENT_OPTIONS} onChange={handleChange} />
        </div>
        <div className="card-errors" role="alert">
          {error}
        </div>
      </div>
      <button type="submit" className="btn">
        Submit Payment
      </button>
    </form>
  );
};

export default CheckoutForm;
